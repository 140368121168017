/* Utils */
.clearfix:after {
    content: " ";
    display: block;
    clear: both;
}

.card {
    background: #fcfcfc;
    padding: 0.1rem;
    margin-bottom: 30rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.p-toast {
    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 100px;
    }
}
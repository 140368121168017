h1, h2, h3, h4, h5, h6 {
    margin: 1.5rem 0 1rem 0;
    font-family: inherit;
    font-weight: 450;
    line-height: 1;
    color: #262E37;

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.5rem;
}

h3 {
    font-size: 1.25rem;
}

h4 {
    font-size: 1rem;
}

h5 {
    font-size: 0.75rem;
}

h6 {
    font-size: 0.5rem;
}

mark {
    background: #FFF8E1;
    padding: .2rem .35rem;
    border-radius: $borderRadius;
    font-family: monospace;
}

blockquote {
    margin: 1rem 0;
    padding: 0 1.5rem;
    border-left: 4px solid #90A4AE;
}

hr {
    border-top: solid var(--surface-border);
    border-width: 1px 0 0 0;
    margin: 1rem 0;
}

p {
    margin: 0 0 1rem 0;
    line-height: 1;

    &:last-child {
        margin-bottom: 0;
    }
}

.p-dialog {
  width: 30vw;
}

@media (max-width: 1680px) {
  .p-dialog {
    width: 45vw;
  }
}

@media (max-width: 1280px) {
  .p-dialog {
    width: 55vw;
  }
}

@media (max-width: 800px) {
  .p-dialog {
    width: 60vw;
  }
}

@media (max-width: 600px) {
  .p-dialog {
    width: 100vw;
  }
}


.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 5rem;
    transition: margin-left $transitionDuration;
}

.layout-main {
    flex: 1 1 auto;
    background: #fcfcfc;
}
